import React, {useEffect, useState} from 'react';
import FooterSection from '../../../components/FooterSection/Footer';
import DiscountDetailFirstSectionStoreDiscounts
    from "../../../components/AppSections/StoreDiscountsPageSections/DiscountDetailFirstSectionStoreDiscounts";
import {useParams} from "react-router-dom";
import {useDispatch} from "react-redux";
import {getDiscountDetailsCall} from "../../../api/ApiCalls";
import RedirectToAppModalComponent from "../../../components/AppComponents/RedirectToAppModalComponent";
import {Helmet} from "react-helmet";
import {appWords} from "../../../config/Constants";
import {stringsTranslate} from "../../../locales/CustomTranslater";
import SimilarStoreDiscounts from "../../../components/AppSections/StoreDiscountsPageSections/SimilarStoreDiscounts";

export default function StoreDiscountInfo() {
    const { id } = useParams();
    const dispatch = useDispatch();
    const [storeDiscount, setStoreDiscount] = useState(undefined);
    const [isLoading, setIsLoading] = useState(false);
    const [showRedirectAppModal, setShowRedirectAppModal] = useState(false);
    const online_discounts_cm = stringsTranslate(appWords.online_discounts_cm);
    const [error, setError] = useState(false);
    const initStrings = {
        discountNotFound: stringsTranslate(appWords.discountNotFound),
        discountNotFoundMessage: stringsTranslate(appWords.discountNotFoundMessage),
    }
    useEffect(async () => {
        setIsLoading(true);
        try {
            const discount = await getDiscountDetailsCall(parseInt(id));
            if (!discount) {
                throw new Error('Discount not found');
            }
           // dispatch(updateStoreDiscountsRS(discount));
            setStoreDiscount(discount);
        }catch (e) {
            console.error('Error fetching discounts', e);
            setError(true);
        }finally {
            setIsLoading(false);
        }
    }, [id, dispatch]);

    useEffect(() => {
        if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
            setShowRedirectAppModal(true)
        }
    }, [id])


    if (isLoading) {
        return (
            <div className="text-center m-2">
                <div className="spinner-border text-knaek-secondary"></div>
            </div>
        );
    }
    return (
        <div className="homepage-4 pt-5 mt-5">
            <Helmet>
                <title>Knaek - {online_discounts_cm}</title>
            </Helmet>
            <div className="main">
                        <RedirectToAppModalComponent showModal={showRedirectAppModal}
                                                     closeModal={() => setShowRedirectAppModal(!showRedirectAppModal)}
                                                     storeDiscount={storeDiscount}
                                                     storeID={id} link={'knaek://Homepage?instore=true&id='}
                                                     online={false} icon={storeDiscount?.company.logo}
                                                     title={storeDiscount?.localization['nl']?.title}/>
                {error ? (
                    <div className="text-center m-4">
                        <h3>{initStrings.discountNotFound}</h3>
                        <p>{initStrings.discountNotFoundMessage}</p>
                    </div>
                ) : (
                    storeDiscount && (
                        <>
                            <DiscountDetailFirstSectionStoreDiscounts storeDiscount={storeDiscount} />
                            <SimilarStoreDiscounts storeDiscount={storeDiscount} />
                        </>
                    )
                )}
                        <FooterSection/>
                    </div>
                </div>
                );
}
